import { Provider, darkTheme, Grid, View, Flex, IllustratedMessage, Heading, Content, StatusLight, TextArea, ActionButton, TableView, TableHeader, Column, TableBody, Row, Cell } from '@adobe/react-spectrum';
import Refresh from '@spectrum-icons/workflow/Refresh';
import Table from '@spectrum-icons/workflow/Table';
import React from 'react'

const SAMPLE_CODE = 'let f x = if x then "hi" else "ho"'

type Runner = (code: string) => Promise<{ success: boolean, output: string }>;

function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

let strains = ["CoViD-19", "Alpha", "Beta", "Gamma", "Delta", "Omicron"]

let DEFAULT_STATUS = {
  size: 0,
  variants: []
}
for (let i = 0; i < 5; i++) {
  let current = {
    iteration: i,
    code: null,
    error: null,
    definesF: undefined,
    isQuine: undefined,
    canMutate: undefined,
    recursive: undefined,
    fFalseOutput: null,
    fTrueOutput: null,
  }
  DEFAULT_STATUS.variants.push(current);
}


async function* check(quine: string, runner: Runner, depth = 5) {
  let result = JSON.parse(JSON.stringify(DEFAULT_STATUS))


  if (!quine) { yield JSON.parse(JSON.stringify(result)); return result; }
  result.size = quine.length;
  result.variants[0].code = quine

  yield JSON.parse(JSON.stringify(result))

  let counter = 0;
  await sleep(100)

  while (counter < depth) {
    let current = result.variants[counter];

    yield JSON.parse(JSON.stringify(result))

    await sleep(100)
    let orig = await runner(current.code);
    current.definesF = false;
    current.error = orig.output
    if (!orig.success) { yield JSON.parse(JSON.stringify(result)); return result; }
    current.error = null

    let fFalse = await runner(current.code + "\nlet _ = print_string (f false);;");
    current.error = fFalse.output
    if (!fFalse.success) { yield JSON.parse(JSON.stringify(result)); return result; }
    current.fFalseOutput = fFalse.output;
    current.isQuine = current.fFalseOutput === current.code
    yield JSON.parse(JSON.stringify(result))

    await sleep(100)
    let fTrue = await runner(current.code + "\nlet _ = print_string (f true);;");
    current.definesF = fTrue.success && fFalse.success;
    current.error = fTrue.output
    if (!fTrue.success) { yield JSON.parse(JSON.stringify(result)); return result; }
    current.fTrueOutput = fTrue.output;
    current.error = null
    current.canMutate = current.fTrueOutput !== current.code
    yield JSON.parse(JSON.stringify(result))

    await sleep(100)

    if (counter < depth - 1) result.variants[counter + 1].code = current.fTrueOutput;

    if (!current.isQuine || !current.canMutate) break;
    counter++;
  }
  yield JSON.parse(JSON.stringify(result)); return result;

}


export let App: React.FC<{ runner: Runner }> = function ({ runner }) {
  const [code, setCode] = React.useState("");
  const [checking, setChecking] = React.useState(false);
  const [status, setStatus] = React.useState(DEFAULT_STATUS)

  let toStatusVariant = (v: boolean | undefined) => ({
    "true": "positive",
    "undefined": "neutral",
    "false": "negative",
  }['' + v]! as "positive" | "neutral" | "negative")


  return (
    <Provider theme={darkTheme}>
      <Grid
        areas={
          [
            'status  content',
            'footer  footer'
          ]
        }
        columns={['1fr', '4fr']}
        rows={['size-3400', 'size-6000']}
        gap="size-200">
        <View backgroundColor="gray-500" gridArea="status">
          <Flex
            direction={'column'}
            gap="size-100"
            margin="size-100">
            Checklist:

            <ul>
            <li>(1) Defines function <code>f: bool -&gt; string</code></li>
            <li>(2) Returns own definition for <code>f false</code></li>
            <li>(3) Returns something else for <code>f true</code></li>
            <li>(4) Mutated definition satisfies (1)-(4)</li>

            </ul>

            <StatusLight variant="chartreuse">Size: {status.size} chars</StatusLight>
            <ActionButton isDisabled={status.variants[0] && status.variants[0].code === code || checking} onPress={async () => {
              console.log("checking", code)
              setChecking(true)
              for await (let status of check(code, runner)) {
                console.log(status)
                setStatus(status)
              }
              setChecking(false)
            }}>
              <Refresh />
              Refresh
            </ActionButton>
          </Flex>
        </View>
        <View gridArea="content" >
          <textarea
            onChange={e => setCode(e.target.value)}
            placeholder={SAMPLE_CODE}
            style={{ width: '97%', height: '90%', padding: '1em', backgroundColor: "#121212", color: "white" }} />
        </View>



        <View aria-label="resgrid" gridArea="footer">
          <table width="100%" style={{tableLayout: 'fixed'}}>
            <thead>
              <tr>
                <th style= {{width: "6em"}}>Strain</th>
                <th style= {{width: "2em"}}>(1)</th>
                <th style= {{width: "2em"}}>(2)</th>
                <th style= {{width: "2em", paddingRight: '1em'}}>(3)</th>
                <th>Code &amp; Output</th>
              </tr>
            </thead>
            <tbody>
              {status.variants.map(item => (
                <tr key={"blablablub1123123"+item.iteration}>
                  <td>
                    {strains[item.iteration]}</td>
                  <td><StatusLight variant={toStatusVariant(item.definesF)} /></td>
                  <td><StatusLight variant={toStatusVariant(item.isQuine)} /></td>
                  <td><StatusLight variant={toStatusVariant(item.canMutate)} /></td>
                  <td>          {
                    item.error !== null ? <pre color="red">{item.error.replace("File \"/a.ml\", l", "L")}</pre>
                      :
                      <pre>
                      Code:{'   '} {item.code || "-"}<br />
                      f false: {item.fFalseOutput || "-"}
                      </pre>
                }</td>
                </tr>
              ))}
            </tbody>
          </table>

          {
              /* TableHeader aria-label="restable" columns={columns}>
              {column => (
              <Column
                key={column.uid}
                align='start'>
                {column.name}
              </Column>
              )}
              </TableHeader>
              <TableBody items={status.variants}>
              {item => (
                <Row key={item.iteration}>
                  <Cell>
                    {strains[item.iteration]}</Cell>
                  <Cell>{JSON.stringify(item)}</Cell>
                  <Cell>c</Cell>
                  <Cell>d</Cell>
                  <Cell>          {
                  item.error !== null ? <pre color="red">{item.error}</pre>
                   :
                  <>everything alright</>}</Cell>
                </Row>
              ) }
              </TableBody>
                  */ }
        </View>
      </Grid>
    </Provider>
  );
}
