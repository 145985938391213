import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import { Content, darkTheme,Flex, Heading, IllustratedMessage, Provider } from '@adobe/react-spectrum';
import {ProgressBar} from '@adobe/react-spectrum'




import { ExecCore } from 'wasi-kernel';
import baseZipUri from "url:../base.zip"
import ocamlUri from "url:../ocaml.bin"
import ocamlrunWasmUri from "url:../ocamlrun.wasm"

import fs from 'fs';
import path from 'path';
import mkdirp from 'mkdirp';
import { Buffer } from 'buffer';
import JSZip, { JSZipObject } from 'jszip';


function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

// window.Buffer = Buffer;
// HACK ALERT: somewhere down the dependency chain (wasi-kernel <-> memfs interaction), things blow up :/
// workaround a failing isBuffer check (on an Uint8Array) by doing this instead:
(Buffer as any).isBuffer = function isBuffer (b: any) {
    return b != null && (b._isBuffer === true || b.BYTES_PER_ELEMENT) &&
        b !== Buffer.prototype // so Buffer.isBuffer(Buffer.prototype) will be false
}

type UnzipOptions = {
    to?: {fs?: typeof fs, directory?: string}
};

async function unzip(zipfile: ArrayBuffer, opts: UnzipOptions) {
    var {to: {directory: odir, fs: ofs}} = opts,
        z = await JSZip.loadAsync(zipfile),
        promises = [];
    z.forEach((relativePath: string, entry: JSZipObject) => {
        promises.push((async () => {
            // HACK ALERT
            // this is only required because of the isBuffer hack above
            relativePath = relativePath.split(",").map(x => String.fromCharCode(parseInt(x, 10))).join('')
            var outf = path.join(odir, relativePath);
            if (entry.dir) {
                mkdirp.sync(outf, {fs: ofs});
            }
            else {
                mkdirp.sync(path.dirname(outf), {fs: ofs});
                let content = await entry.async('uint8array')
                ofs.writeFileSync(outf, Buffer.from(content));
            }
        })());
    });
    await Promise.all(promises);
}

function fetchWithProgress(url: string, progress: (v: number) => void): Promise<ArrayBuffer> {
    return new Promise(function (resolve, reject) {
      // fetch doesn't really work here because there's no onprogress
      let xhr = new XMLHttpRequest();
      xhr.responseType = "arraybuffer";
      xhr.open("GET", url);

      xhr.onprogress = event => {
        progress(event.loaded / event.total)
        console.log(`Received ${event.loaded} of ${event.total}`);
      };

      xhr.onload = () => {
        if(xhr.status != 200) {
          console.error(`Error ${xhr.status}: ${xhr.statusText}`);
          reject(`Error ${xhr.status}: ${xhr.statusText}`)
        } else {
          // make sure progress events get delivered before the onload event
          setTimeout(() => resolve(xhr.response))
        }
      };

      xhr.send();
    })
  }


async function main() {
    console.log("new ExecCore")
    var td = new TextDecoder('utf-8');

    // TODO: p = new WorkerProcess('/uri/of/prog.wasm');?
    var core = new ExecCore({tty: true});
    core.tty.on('data', x => console.log("output", td.decode(x)));

    // TODO: we dont need ocamlrun in zip?
    let baseZip = new Uint8Array(await (await fetch(baseZipUri)).arrayBuffer())
    let ocamlExe = Buffer.from(await (await fetch(ocamlUri)).arrayBuffer())

    console.log("unzip")
    await unzip(baseZip, {
        to: { directory: '/usr/local/lib/ocaml', fs: core.wasmFs.fs }
    });
    core.wasmFs.fs.writeFileSync('/usr/local/lib/ocaml/ocaml', ocamlExe)

    console.log("writeFileSync /a.ml")
    core.wasmFs.fs.writeFileSync('/a.ml',
        `print_int @@ 4 + 9;; print_newline ();;
         print_endline "I am sane.";;`)

    console.log("start ocamlrun")
    await core.start(ocamlrunWasmUri,
        ['ocamlrun', '/usr/local/lib/ocaml/ocaml', '/a.ml']);
    console.log("start ocamlrun v2")
    core.wasmFs.fs.writeFileSync('/a.ml',
        `print_endline "heyho";;`)
    let res = await core.start(ocamlrunWasmUri,
        ['ocamlrun', '/usr/local/lib/ocaml/ocaml', '/a.ml']);
    console.log("res:", res);
    console.log("start ocamlrun v3")
    core.wasmFs.fs.writeFileSync('/a.ml',
            `let f x = 42;; print_endline f true;;`)
    res = await core.start(ocamlrunWasmUri,
            ['ocamlrun', '/usr/local/lib/ocaml/ocaml', '/a.ml']);
    console.log("res:", res);
    // TODO: check that this works with timeouts?
}

function Loader() {
  const [runner, setRunner] = React.useState(undefined)
  const [status, setStatus] = React.useState({
    heading: 'Preparing the environment',
    content: "reticulating splines...",
    progress: null
  })

  React.useEffect(() => {
    (async () => {
      await sleep(250)
      setStatus({
        heading: 'Preparing the environment',
        content: 'Loading stdlib',
        progress: null
      })
      let baseZip = new Uint8Array(await fetchWithProgress(baseZipUri, progress => setStatus({
        heading: 'Preparing the environment',
        content: 'Downloading stdlib',
        progress
      })))
      await sleep(250)
      setStatus({
        heading: 'Preparing the environment',
        content: 'Loading compiler',
        progress: null
      })
      let ocamlExe = Buffer.from(await fetchWithProgress(ocamlUri, progress => setStatus({
        heading: 'Preparing the environment',
        content: 'Downloading compiler',
        progress
      })))

      await sleep(250)
      setStatus({
        heading: 'Preparing the environment',
        content: 'Extracting standard library',
        progress: null
      })
      let core = new ExecCore({tty: true});

      console.log("unzip")
      await unzip(baseZip, {
          to: { directory: '/usr/local/lib/ocaml', fs: core.wasmFs.fs }
      });
      core.wasmFs.fs.writeFileSync('/usr/local/lib/ocaml/ocaml', ocamlExe)

      await sleep(250)
      setRunner(() => async function runner(code: string) {
        console.log("ocaml runner:", code)
        core.wasmFs.fs.writeFileSync('/a.ml', code)
        let td = new TextDecoder('utf-8');
        let output = ""
        core.tty.removeAllListeners('data')
        core.tty.on('data', x => {
          let chunk = td.decode(x)
          console.log("stdout chunk:", chunk)
          output += chunk
        });
        let exitCode = await core.start(ocamlrunWasmUri,
          ['ocamlrun', '/usr/local/lib/ocaml/ocaml', '/a.ml']);
        console.log("exit code:", exitCode)
        return {
          success: exitCode === 0,
          output
        }
      })

      await sleep(1000);
      window.resizeTo(500, 500);
      await sleep(1000);
      window.resizeBy(500, 500);
      console.log("forcing resize")

    })()
  }, [])

  if (runner !== undefined)
    return <App runner={runner} />

  return <Provider theme={darkTheme}>
    <Content flexGrow={1} UNSAFE_style={{height: '100vh'}}>
      <IllustratedMessage>
        <img width="137" style={{order: 1, filter: "invert(1)", opacity: 0.8}} src="https://try.ocamlpro.com/icons/tryocaml_loading_8.gif" />
        <Heading>{status.heading}</Heading>
        <Content>
          {status.progress !== null ?(
            <>
              <br />
              <ProgressBar label={status.content + "…"} value={status.progress * 100} />
            </>
          ): status.content}
        </Content>
      </IllustratedMessage>
    </Content>
  </Provider>
}

{
  ReactDOM.render(
    <React.StrictMode>
      <Loader />
    </React.StrictMode>,
    document.getElementById('app')
  );
}
